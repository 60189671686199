.controller {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .horizontal-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
  }
  
  .control-btn {
    width: 50px;
    height: 50px;
    background: transparent;
    border: none;
    padding: 0;
    margin: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .control-btn:active {
    opacity: 0.7;
  }
  
  .control-btn img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  .control-btn:active img {
    filter: brightness(0.8);
  }