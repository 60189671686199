.gameplay-wrapper {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.controls-overlay {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
}

.gameplay {
    position: absolute;
    top: 0;
    left: 0;
}

.game-canvas {
    image-rendering: pixelated;
    image-rendering: crisp-edges;
}

.game-grid {
    display: grid;
    width: 100%;
    height: 100%;
}

.game-cell {
    position: relative;
    overflow: hidden;
}

.game-character {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.game-cell, .game-character {
  image-rendering: pixelated;
}

.player {
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  width: 10%;
  height: 20%;
  background-image: url('../assets/rheed.png');
  background-position: center;
  transition: left 0.2s, top 0.2s;
}

.grid-line {
    position: absolute;
    background-color: rgba(255, 0, 0, 0.5);
}

.grid-line.vertical {
    width: 2px;
    height: 100%;
}

.grid-line.horizontal {
    width: 100%;
    height: 2px;
}

.collision-map {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    pointer-events: none;
}

.dev-controls {
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 10px;
    border-radius: 5px;
    color: white;
}

.dev-controls label {
    margin-right: 10px;
}

.dev-controls input {
    width: 100px;
}

.confirmation-dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgb(111, 162, 255);
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
    z-index: 1000;
}

.confirmation-dialog button {
    margin: 0 10px;
    padding: 5px 10px;
    cursor: pointer;

}