.game-container {
  position: relative;
  width: 300px;
  height: 300px;
  margin: 0 auto;
}

body {
  margin: 0;
  padding: 0;
  background-color: rgb(79, 134, 218);
  color: white;
}

.App {
  text-align: center;
}