.level-editor {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
  
  .canvas {
  width: 100%;
  background-color: #7d6249; /* This will now show through transparent tiles */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  padding: 20px;
  box-sizing: border-box;
}

.grid {
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 0;
  padding-bottom: 50%; /* Adjust this value to match the aspect ratio of your image */
  position: relative;
}

.cell {
  position: absolute;
  width: 6.66%; /* 100% / 15 columns */
  height: 10%; /* 100% / 10 rows */
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.cell::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 255, 0.3); /* Blue with 30% opacity */
  opacity: 0;
  transition: opacity 0.1s ease;
}

.cell:hover::after {
  opacity: 1;
}

.cell[style*="backgroundImage"]::after {
  background-color: rgba(0, 0, 255, 0.15); /* Lighter blue for cells with tiles */
}

.cell.wall {
  background-color: #333;
}

.cell.start {
  background-color: #4CAF50;
}

.cell.end {
  background-color: #f44336;
}

/* You may want to adjust these styles based on your actual tile images */
.cell[style*="backgroundImage"] {
  background-size: contain; /* Changed from cover to contain */
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent; /* Ensure background is transparent */
}

@media (min-width: 768px) {
  .grid {
    transform: scale(7);
    transform-origin: center;
  }
}

.editor-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px;
  background-color: #2c2c2c;
}

.custom-dropdown {
  position: relative;
  display: inline-block;
}

.custom-dropdown button {
  display: flex;
  align-items: center;
  background-color: #3c3c3c;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #3c3c3c;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  max-height: 300px;
  overflow-y: auto;
}

.dropdown-item {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #4c4c4c;
}

input[type="text"] {
  background-color: #3c3c3c;
  color: white;
  border: none;
  padding: 10px;
  margin-right: 10px;
}
.editor-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #2c2c2c;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.2);
    z-index: 10;
  }
  
  .menu-item {
    margin-bottom: 15px;
  }
  
  .menu-item label {
    display: block;
    margin-bottom: 5px;
    color: white;
  }
  
  .menu-item input,
  .menu-item select {
    width: 100%;
    padding: 5px;
    background-color: #3c3c3c;
    color: white;
    border: none;
    border-radius: 3px;
  }
  
  .tile-selector {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    margin-top: 15px;
  }
  
  .tile-button {
    background-color: transparent;
    border: 2px solid transparent;
    padding: 5px;
    cursor: pointer;
    transition: border-color 0.3s;
  }
  
  .tile-button.selected {
    border-color: #4CAF50;
  }
  
  .tile-button img {
    width: 100%;
    height: auto;
  }

.edit-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.edit-button img {
  width: 24px;
  height: 24px;
}